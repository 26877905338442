import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Employee,Hobbies,Education,Family,Emergency,Health } from './employee';
import { environment } from './../../environments/environment';
import { map,catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  
  APIEndpoint = environment.APIEndpoint;
  private baseUri=this.APIEndpoint;  
  private headers=new HttpHeaders().set('Content-Type','application/json');

  constructor(private _http:HttpClient) { }


  private extractData(res: Response) {
    let body = res;
    return body || { };
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

  // Registerlist(): Observable<any> {

  //   return this._http.get(this.baseUri+'/getemp', {headers:this.headers}).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError));    
  // }

  getRegisterList(code): Observable<any> {

 //   console.log(code,'inside read employee list in service page')
    return this._http.post(this.baseUri+'/getemphobby',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  employeeList(code): Observable<any> {

    return this._http.post(this.baseUri+'/getemp',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  addressList(code): Observable<any> {

    return this._http.post(this.baseUri+'/getempaddress',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  educationList(code): Observable<any> {

    console.log(code,'inside read education list in service page')
    return this._http.post(this.baseUri+'/getempeducation',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  bankAccountList(code): Observable<any> {

    console.log(code,'inside read Bank account list in service page')
    return this._http.post(this.baseUri+'/getempbanklist',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  familyList(code): Observable<any> {

    console.log(code,'inside read family list in service page')
    return this._http.post(this.baseUri+'/getempfamily',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  emergencyList(code): Observable<any> {

    console.log(code,'inside read emergency list in service page')
    return this._http.post(this.baseUri+'/getempemergency',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  healthList(code): Observable<any> {

    console.log(code,'inside read employee list in service page')
    return this._http.post(this.baseUri+'/gethealthdetails',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
  }

  employeeRegister(employee:Employee)
  {
     console.log('inside register Angular service ' +employee) ;
    
    return this._http.post(this.baseUri+'/postempdetails',employee,{headers:this.headers});
    
  }

  addressRegister(employee:Employee)
  {
     console.log('inside register Angular service ' +employee) ;
    
    return this._http.post(this.baseUri+'/postempaddress',employee,{headers:this.headers});
    
  }

  educationRegister(education:Education)
  {
     console.log('inside register Angular service ' +education) ;
    
    return this._http.post(this.baseUri+'/postempedu',education,{headers:this.headers});
    
  }

  bankRegister(employee:Employee)
  {
     console.log('inside BANK register Angular service ' +employee) ;
    
    return this._http.post(this.baseUri+'/postempbankdetails',employee,{headers:this.headers});
    
  }

  familyRegister(family:Family)
  {
     console.log('inside family register Angular service ' +family) ;
    
    return this._http.post(this.baseUri+'/postfamily',family,{headers:this.headers});
    
  }

  emergencyRegister(emergency:Emergency)
  {
     console.log('inside emergency register Angular service ' +emergency) ;
    
    return this._http.post(this.baseUri+'/postemergency',emergency,{headers:this.headers});
    
  }

  healthRegister(health:Health)
  {
     console.log('inside Health register Angular service ' +health) ;
    
    return this._http.post(this.baseUri+'/posthealth',health,{headers:this.headers});
    
  }

  fileupload(employee:Employee)
  {
    console.log(employee.code);
    console.log(employee.location);
    console.log(employee.location_remarks);

    // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
    // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
   
    return this._http.post(this.baseUri+'/postfile',employee,{headers:this.headers});
  }

//---------- Update API----------

    updateEmployee(employee:Employee)
    {
      console.log('Update Employee Angular service ' +employee) ;
      
      return this._http.post(this.baseUri+'/updateempdetails',employee,{headers:this.headers});
      
    }
  
    updateAddress(address:Employee)
    {
      console.log('Update Address Angular service ' +address) ;
      
      return this._http.post(this.baseUri+'/updateempaddress',address,{headers:this.headers});
      
    }

    updateEducation(education:Employee)
    {
      console.log('Update Education Angular service ' +education) ;
      
      return this._http.post(this.baseUri+'/updateempedu',education,{headers:this.headers});
      
    }

    updateBankAccount(bankAccount:Employee)
    {
      console.log('Update BankAccount Angular service ' +bankAccount) ;
      
      return this._http.post(this.baseUri+'/updateempbankdetails',bankAccount,{headers:this.headers});
      
    }

    updateFamily(family:Employee)
    {
      console.log('Update Family Angular service ' +family) ;
      
      return this._http.post(this.baseUri+'/updateempfamily',family,{headers:this.headers});
      
    }

    updateEmergency(emergency:Employee)
    {
      console.log('Update Emergency Angular service ' +emergency) ;
      
      return this._http.post(this.baseUri+'/updateempemergency',emergency,{headers:this.headers});
      
    }

    updateHealth(health:Employee)
    {
      console.log('Update Health Angular service ' +health) ;
      
      return this._http.post(this.baseUri+'/updateemphealth',health,{headers:this.headers});
      
    }

    //Dashobard Profile card
    getProfileCard(profile:Employee)
    {
      return this._http.post(this.baseUri+'/getuser',profile,{headers:this.headers});
    }

//-------------------------Drop Down --------------------------
   
   getGroupList()
    {
      return this._http.get(this.baseUri+'/getbloodgroup',{headers:this.headers});
    }

    getStateList()
    {
      return this._http.get(this.baseUri+'/getstate',{headers:this.headers});
    }

    getCountryList()
    {
      return this._http.get(this.baseUri+'/getcountry',{headers:this.headers});
    }

    getQualificationList()
    {
      return this._http.get(this.baseUri+'/getqualification',{headers:this.headers});
    }

    getBankList()
    {
      return this._http.get(this.baseUri+'/getbank',{headers:this.headers});
    }       

    getDepartmentList()
    {
      return this._http.get(this.baseUri+'/getdepartmentlist',{headers:this.headers});
    }
  
    getDesignationList()
    {
      return this._http.get(this.baseUri+'/getdesignationlist',{headers:this.headers});
    }
  
    getDocumentTypeList()
    {
      return this._http.get(this.baseUri+'/getdoc',{headers:this.headers});
    }

//Create
    addHobbyDetails(hobbies:Hobbies)
    {
    
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/posthobby ',hobbies,{headers:this.headers});
    }
  
    addDepartmentDetails(employee:Employee)
    {
      console.log(employee.code);
      console.log(employee.department_id);
    
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/postempdepartment',employee,{headers:this.headers});
    }
  
    addDesignationDetails(employee:Employee)
    {
      console.log(employee.code);
      console.log(employee.designation_id);
    
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/postempdesignation',employee,{headers:this.headers});
    }
  
    addSalaryDetails(employee:Employee)
    {   
      console.log(employee.code);
      console.log(employee.ctc_per_annum);
      console.log(employee.ctc_per_month);
      console.log(employee.gross_salary);
      console.log(employee.net_salary);
    
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/postempsalary',employee,{headers:this.headers});
    }
  
    addWorkLocationDetails(employee:Employee)
    {
      console.log(employee.code);
      console.log(employee.location);
      console.log(employee.location_remarks);
  
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/postempworklocation',employee,{headers:this.headers});
    }
  
    addRemarksDetails(employee:Employee)
    {
      console.log(employee.code);
      console.log(employee.rem_remarks);
      console.log(employee.rem_description);
  
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      return this._http.post(this.baseUri+'/postempremarks',employee,{headers:this.headers});
    }
  
    addAttachmentDetails(employee:Employee)
    {
      console.log(employee.code);
      console.log(employee.document_type_id);
      console.log(employee.doc_no);
      console.log(employee.doc_name);
      console.log(employee.doc_ref);
      console.log(employee.doc_remarks);
      console.log(employee.doc);
  
      // this.sessionvalue = JSON.parse(localStorage.getItem("LoggedInUser"));
      // ticket.login_employee_id = this.sessionvalue['msg'][0]['id']; 
     
      // return this._http.post(this.baseUri+'/postfile',employee,{headers:this.headers});
      return this._http.post(this.baseUri+'/postempdoc',employee,{headers:this.headers});
    }

// List
    hobbyList(code): Observable<any> {
  
      console.log(code,'inside read hobbyList in service page')
      return this._http.post(this.baseUri+'/getemphobby',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));    
    }
  
    departmentList(code): Observable<any> {
  
      console.log(code,'inside read departmentList in service page')
      return this._http.post(this.baseUri+'/getdepartment',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
  
    }
  
    designationList(code): Observable<any> {
  
      console.log(code,'inside read designationList in service page')
      return this._http.post(this.baseUri+'/getdesignation',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
   
    }
  
    salaryList(code): Observable<any> {
  
      console.log(code,'inside read salaryList in service page')
      return this._http.post(this.baseUri+'/getsalary',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
   
    }
  
    workLocationList(code): Observable<any> {
  
      console.log(code,'inside read workLocationList in service page')
      return this._http.post(this.baseUri+'/getworklocation',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
  
    }
    
    
    remarksList(code): Observable<any> {
  
      console.log(code,'inside read remarksList in service page')
      return this._http.post(this.baseUri+'/getremarks',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
  
    }
  
    attachmentList(code): Observable<any> {
  
      console.log(code,'inside read attachmentList in service page')
      return this._http.post(this.baseUri+'/getattachment',{code:code},{headers:this.headers}).pipe(
      map(this.extractData),
      catchError(this.handleError));   
  
    }
  
  
//Hobby update
  updateHobby(hobby:Hobbies)
   {  
    return this._http.post(this.baseUri+'/updateemphobby',hobby,{headers:this.headers});   
   }
  
//Department update  
    updateDepartment(employee:Employee)
    {  
    console.log("inside updateDepartment service===>"+employee.code);
    console.log(employee.id);
    console.log(employee.code);
    console.log(employee.department_id);
    return this._http.post(this.baseUri+'/updateempdepartment',employee,{headers:this.headers});  
    }
  
//Designation update
    updateDesignation(employee:Employee)
    {  
    console.log("inside updateDesignation service===>"+employee.code);
    console.log(employee.code);
    console.log(employee.designation_id);
    return this._http.post(this.baseUri+'/updateempdesignation',employee,{headers:this.headers});  
    }
  
//Salary update
    updateSalary(employee:Employee)
    {  
    console.log("inside updateSalary service===>"+employee.code);
    console.log(employee.code);
    console.log(employee.ctc_per_annum);
    console.log(employee.ctc_per_month);
    console.log(employee.gross_salary);
    console.log(employee.net_salary);
    return this._http.post(this.baseUri+'/updateempsalary',employee,{headers:this.headers});  
    }
  
//WorkLocation update
    updateWorkLocation(employee:Employee)
      {  
      console.log("inside updateWorkLocation service===>"+employee.code);
      console.log(employee.code);
      console.log(employee.location);
      console.log(employee.location_remarks);
      return this._http.post(this.baseUri+'/updateempworklocation',employee,{headers:this.headers});  
      }
  
//Remarks update
    updateRemarks(employee:Employee)
      {  
      console.log("inside updateRemarks service===>"+employee.code);
      console.log(employee.code);
      console.log(employee.rem_remarks);
      console.log(employee.rem_description);
      return this._http.post(this.baseUri+'/updateempremarks',employee,{headers:this.headers});  
      }
  

//forgot password
sendNewPwd(forgotpassword:Employee){
  console.log('inside login forgot password service file');
  
  return this._http.post(this.baseUri+'/passwordUpdate',forgotpassword,{headers:this.headers});
}
//######################################################################################################
  // getRegisterList()
  // {
  //   return this._http.get(this.baseUri+'/getregisterdetail',{headers:this.headers});
  // }

  // getSingleRegister(family_id)
  // {
  //   console.log('inside read',family_id);
    
  //   return this._http.post(this.baseUri+'/getsinleregister',{family_id:family_id},{headers:this.headers});
  // }

  // updateRegister(register:Register)
  // {
  //   console.log('Update Service',register);
  //   console.log('Update Service',register.family_id);

  //   return this._http.post(this.baseUri+'/updateregister',register,{headers:this.headers});
  // }

  // private extractData(res: Response) {
  //   let body = res;
  //   return body || { };
  // }
  // private handleError(error: HttpErrorResponse) {
  //   if (error.error instanceof ErrorEvent) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error.message);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong,
  //     console.error(
  //       `Backend returned code ${error.status}, ` +
  //       `body was: ${error.error}`);
  //   }
  //   // return an observable with a user-facing error message
  //   return throwError('Something bad happened; please try again later.');
  // };

  // Registerlist(): Observable<any> {
  //   //return this._http.get(this.baseUri+'/employee/get',{headers:this.headers});
  //   return this._http.get(this.baseUri+'/getregisterdetail', {headers:this.headers}).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError));
  //   /*return this._http.get(this.baseUri+"/employee/get").pipe(
  //     map((combinedResponse) => {
  //        return combinedResponse
  //     }))*/
  // }

  // deleteRegister(mobile)
  //   {
  //     return this._http.post(this.baseUri+'/deleteregister',{mobile:mobile},{headers:this.headers});
  //   }

}
