import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { DialogExampleComponent } from './dialog-example/dialog-example.component';

import { SessionoutComponent } from './sessionout/sessionout.component';

//import { ErrorDialogComponent } from './shared/dialogs/error-dialog/error-dialog.component';
//import { SuccessDialogComponent } from './shared/dialogs/success-dialog/success-dialog.component';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';

import { MatProgressSpinnerModule } from '@angular/material';
import {MatIconModule,MatTabsModule,
  MatButtonModule,MatFormFieldModule,MatCheckboxModule,
  MatInputModule,MatRippleModule, MatSelectModule,MatPaginatorModule,MatSortModule,MatDialogModule,
  MatDatepickerModule, MatNativeDateModule,MatTooltipModule,MatMenuModule, MatGridListModule, MatToolbarModule, 
  MatSnackBarModule, MatTableModule,MatCardModule,
    MatSidenavModule} from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    DialogExampleComponent,SessionoutComponent,
   // ErrorDialogComponent,
  //  SuccessDialogComponent,
    
    
  ],
  entryComponents:[DialogExampleComponent,SessionoutComponent],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,FormsModule , ReactiveFormsModule,
    BrowserAnimationsModule,HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,ModalModule.forRoot(),
    MatButtonModule, MatCheckboxModule, MatSelectModule,MatPaginatorModule,MatSortModule,MatDialogModule,
    MatFormFieldModule, MatInputModule,MatTabsModule,MatCardModule,MatRippleModule,
    MatDatepickerModule,MatNativeDateModule,MatProgressSpinnerModule,MatIconModule,MatSidenavModule,
    MatTooltipModule,MatMenuModule, MatGridListModule, MatToolbarModule,MatSnackBarModule, MatTableModule,
  ],
  providers: [DatePipe,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
