import { Component, OnInit,ViewChild,ViewEncapsulation,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmployeeService } from '../services/employee.service';
import { MatTableDataSource,MatPaginator,MatSort,MatDialogRef,MatDialog, MAT_DIALOG_DATA, } from '@angular/material';

//SnakBar-Flashmessage in angular material design
import {MatSnackBar} from '@angular/material/snack-bar';
import { Employee } from '../services/employee';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sessionout',
  templateUrl: './sessionout.component.html',
  styleUrls: ['./sessionout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SessionoutComponent implements OnInit {

  forgotpassword: Employee = new Employee(); 
  forgotpasswordForm: FormGroup;

  resultresponse:any;
  dataSourceEmployee:any;
  sessionvalue:any;
  code:any;
  category_list:any;
  roleId:any;

  constructor(private employeeService:EmployeeService,public snackbar:MatSnackBar,
    private dialog: MatDialog,private router: Router,
    private dialogRef: MatDialogRef<SessionoutComponent>,) { }
  

  ngOnInit() {

    this.forgotpasswordForm = new FormGroup({
      emp_code: new FormControl(this.forgotpassword.fp_code, [Validators.required, Validators.maxLength(60)]),
      email: new FormControl(this.forgotpassword.fp_email, [Validators.required, Validators.maxLength(60)]),
          
    });
    

  }

  // public hasNewsupdateError = (controlName: string, errorName: string) =>{
  //   return this.newsupdateForm.controls[controlName].hasError(errorName);
  // }

  onSubmit(){

    //   this.showSpinner = true;
         
      console.log(this.forgotpassword.fp_code );
      console.log(this.forgotpassword.fp_email);
  
   
       this.employeeService
       .sendNewPwd(this.forgotpassword)
       .subscribe(result =>{
           this.resultresponse=result['msg'];
           console.log(this.resultresponse);  
           if(this.resultresponse == "Success")
           {
             console.log('inside forgot password form')
             // this.flgmesg(1);
             this.snackbar.open('Password Send Succssfully to your Email-Id!', '', {
               duration: 2000,
               panelClass: ['green-snackbar'],
               verticalPosition: 'top', // 'top' | 'bottom'
               horizontalPosition: 'right',//'start' | 'center' | 'end' | 'left' | 'right'
             });
             //window.location.reload();
             this.closeDialog();
           }                      
           });
   
     }

     closeDialog(){
      // alert('close');
      this.dialogRef.close();
      }

  // public insertNewsUpdate(){
    
  //   var date=new Date();
  //     var currentdate= this.datepipe.transform(date, 'yyyy-MM-dd');
  //     this.newsupdate.from_date=currentdate;
  //     this.newsupdate.to_date=currentdate;

  //     this.newsupdate.ext=this.tempExt;
  //     this.newsupdate.image=this.photo;

  //   console.log(this.newsupdate,'Inside newsupdate Submit Form');
  //   this.newsupdateService.insertNewsUpdate(this.newsupdate).subscribe(
  //     (result)=>{
  //       this.resultresponse=result['msg'];
  //        console.log(result,'NewsUpdate');

  //       if(this.resultresponse == "Success"){
  //        // this.getEmployeeList();
  //         this.getNewsUpdateList();
  //         this.snackbar.open('Successfully added!', '', {
  //           duration: 2000,
  //           panelClass: ['green-snackbar'],
  //           verticalPosition: 'top', // 'top' | 'bottom'
  //           horizontalPosition: 'right',//'start' | 'center' | 'end' | 'left' | 'right'
  //         });          
  //       }
  //       else if(this.resultresponse == "Allready Exists"){

  //         console.log("inside else if");          
  //         this.snackbar.open('Already Exists!', '', {
  //           duration: 2000,
  //           panelClass: ['warning-snackbar'],
  //           verticalPosition: 'top', // 'top' | 'bottom'
  //           horizontalPosition: 'right',//'start' | 'center' | 'end' | 'left' | 'right'
  //         });

  //     } 
  //     else{
  //       console.log(this.resultresponse);
  //       this.snackbar.open('Error!', '', {
  //         duration: 2000,
  //         panelClass: ['red-snackbar'],
  //         verticalPosition: 'top', // 'top' | 'bottom'
  //         horizontalPosition: 'right',//'start' | 'center' | 'end' | 'left' | 'right'
  //       });
  //     }
  //     }); 

  //     console.log('Newsupdate Submit successfully!....',this.newsupdate);
  // }



 
}
