import { Component, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';


import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginService } from './pages/login/login.service';


import { SessionoutComponent } from './sessionout/sessionout.component';

import {MatTableDataSource,MatPaginator,MatSort,MatDialog,MatDialogRef, } from '@angular/material';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
} from '@angular/animations'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  

})

export class AppComponent {
 // title = 'app';
  getRouteAnimation(outlet) {
      
      return outlet.activatedRouteData.animation
  }

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  countdown:any;
  openDialog:any;
  path:any;
path1:any;
path2:any; pathslice:any;
  constructor(private dialog: MatDialog,private idle: Idle, private keepalive: Keepalive,
    private router: Router,) {
      console.log( window.location.href);
      console.log('URL:' + window.location.href);
      console.log('Path:' + window.location.pathname);
      console.log('Host:' + window.location.host);
      console.log('Hostname:' + window.location.hostname);
      console.log('Origin:' + window.location.origin);
      console.log('Port:' + window.location.port);
      console.log('Search String:' + window.location.search);
  //    alert(window.location.href);
      this.path=window.location.pathname;
      this.path1=window.location.href;      
      
//alert(this.router.url);
//alert(this.path);

      if(this.router.url=='/login')
      {
//alert("login")
      } else {
  //      alert("else")
      }
      console.log(router);
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(500);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(100);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();

    });
    
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      //this.openDialog='You will time out in 5 seconds!';
      this.timedOut = true;
      if(this.idleState=='Timed out!'){
       // console.log('SESSION TIMEOUT');
       alert('Session Expired')
        this.router.navigate(['/auth/logout']);
      }
      else{
        console.log('else')
      }
      //alert(this.idleState);
      console.log(this.idleState);
      //alert(this.openDialog) ;     
      //this.router.navigate(['/auth/employee']); 
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
        console.log(this.idleState);
       
        //this.childModal.show();
       
        //alert(this.idleState);

    });

    
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      this.countdown=countdown
      console.log(this.idleState,'timeout message ');
      console.log(countdown,'countown starts in timeout')
      // if(this.idleState=='Timed out!'){
      //   console.log('inside if condition');
      //   alert('dialog open');
      //   this.dialog.open(SessionoutComponent,{
      //     data:{
      //       idleState:this.idleState,
      //       countdown:this.countdown,

      //     }
      //   })

      // }
      
      
     
    //   setTimeout(() => {

    //   console.log('insoide timeout');
    //   this.dialog.open(SessionoutComponent,{
    //     data:{
    //       idleState:this.idleState,
    //     }
        
    //   });
      
    // }, 5000);

      // this.dialog.open(SessionoutComponent,{
      //   data:{
      //     idleState:this.idleState,
      //   }
        
      // });


    });
   
  

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  
}
