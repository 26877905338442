import { Injectable,Output, EventEmitter} from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import {Login} from './login';

import 'rxjs/Rx';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  APIEndpoint = environment.APIEndpoint;
  private baseUri=this.APIEndpoint;
  private headers=new HttpHeaders().set('Content-Type','application/json');
   

  constructor(private myRoute: Router,private _http:HttpClient) { }

  loginemployee(login:Login)
  {
   // this.sessionvalue=JSON.parse(localStorage.getItem("LoggedInUser"));
   // this.createdby =this.sessionvalue['userid'];
   console.log('inside login service',login);
    return this._http.post(this.baseUri+'/getlogin',login,{headers:this.headers});
    
  }

  getToken() {
    //this.getLoggedInName.emit(localStorage.getItem("LoggedInUser"));
      return localStorage.getItem("LoggedInUser")
    }
    isLoggednIn() {
      return this.getToken() !== null;
    }
    signout() {
      localStorage.removeItem("LoggedInUser");
      this.myRoute.navigate(["login"]);
    }

    
  
    validateUserEmail( forgot_pwd_email){
  
      //  console.log("forgot_pwd_email"+forgot_pwd_email);
        return this._http.post(this.baseUri+'/validateuseremail',{email:forgot_pwd_email},{headers:this.headers});
      }
  
    //Reddiyars Login
    changePassword(changePassword:Login){
      console.log('inside change password serice ',changePassword);
      return this._http.post(this.baseUri+'/changepassword',changePassword,{headers:this.headers});
    }  
}
